.dropdown {
  display: flex;
  flex-direction: column;
  width: 280px;
  margin: auto;
}

.dropdown-component {
  padding: 15px;
  width: 280px;
  margin: auto;
  border-color: black;
  border-width: 0.5px;
  border-radius: 5px;
}

.selected-options-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.dropdown-options {
  position: absolute;
  width: 100%;
  border-radius: 0px 0px 16px 16px;
  max-height: 300px;
  overflow: scroll;
  background-color: #fff6ff;
}

.dropdown-option {
  padding: 12px;
}

.dropdown-option:hover {
  color: white;
  background-color: lightblue;
}
