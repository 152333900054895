.ChargesSummary {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ChargesSummary td:first-child {
  padding-right: 10px; /* Adjust the spacing as needed */
}

.ChargesTitle {
  text-decoration: underline;
}
