.guildFormInput {
  display: flex;
  flex-direction: column;
  width: 280px;
  margin: auto;
}

label {
  left: 0;
  margin-bottom: 0px;
}

input {
  padding: 15px;
  margin: 5px 0px;
  border-color: black;
  border-width: 0.5px;
  border-radius: 5px;
}
